// @ts-ignore
/* eslint-disable */
// @ts-ignore
import { Card, Tag,InfiniteScroll } from 'antd-mobile';
import styles from './index.less';
import { list } from './service';
import { useEffect, useState } from 'react';
import { dateFormat } from '../utils/util';
import proxy from '../../../config/proxy';
import Toast from "antd-mobile/es/components/toast";

export default function IndexPage() {
  const [data, setData] = useState<any[]>([]);
  const url = 'https://app-report-image-data.cn-sh2.ufileos.com/';
  const [params, setParams] = useState<any>({
    pageNo: 1,
    pageSize: 20,
    flag: false,
  });
  const [hasMore, setHasMore] = useState<boolean>(false);
  
  useEffect(() => {
    if (!params.flag) {
      loadMore({ flag: false });
    }
  }, [params]);
  const loadMore = async (pm: any = {}) => {
    if (pm.flag) {
      console.log(3333);
      setParams({ ...params, ...pm });
    }
    await loadData({ ...params, ...pm });
  };
  const loadData = async (pm: any = {}) => {
    let temp = data;
    const datas = await list({ ...params, ...pm });
    setData([...temp, ...(datas || [])]);
    setHasMore(datas.length > 0);
  };
  const viewH5 = (record: any) => {
    if(record.status === 2){
      if (record.reportUrl) {
        window.open(record.reportUrl, '_blank');
      } else {
        if (proxy['/api'].target.indexOf('test') > -1) {
          const h5Url = `https://testapi.wzyanche.com/report/h5/${record.code}`;
          window.open(h5Url, '_blank');
        } else {
          const h5Url = `https://api.wzyanche.com/report/h5/${record.code}`;
          window.open(h5Url, '_blank');
        }
      }
    }else{
      Toast.show({
        icon: 'fail',
        content: '报告未审核',
      }) 
    }
  };
  return (
    <div className={styles.body}>
      <div className={styles.text}>
        {data &&
          data.map((item: any, index: number) => {
            return (
              <Card
                style={{ borderRadius: '8px' }}
                onClick={() => {
                  viewH5(item);
                }}
              >
                <div className={styles.content}>
                  <div className={styles.content_img}>
                    <img src={`${url}${item.carMainImage}`} alt="" />
                  </div>
                  <div className={styles.content_text}>
                    <div className={styles.content_car}>
                      {item.carModel} <span>{item.templateName}</span>
                    </div>
                    <div className={styles.content_0}>VIN：{item.vin}</div>
                    {item.mCompanyInfo && item.mCompanyInfo.companyName && (
                      <div className={styles.content_0}>
                        商户：{item.mCompanyInfo.companyName}
                      </div>
                    )}
                    <div className={styles.content_0}>
                      检测时间：
                      {item.createTime
                        ? dateFormat(item.createTime, 'yyyy-MM-dd hh:mm:ss')
                        : '-'}
                    </div>
                    <div className={styles.content_2}>
                      {item.accidentContent && (
                        <Tag
                          color={
                            item.accidentContent.indexOf('排除') > -1 ||
                            item.accidentContent === '未见明显异常'
                              ? 'rgba(21, 209, 65, 1)'
                              : 'danger'
                          }
                        >
                          {item.accidentContent}
                        </Tag>
                      )}
                      {item.fireContent && (
                        <Tag
                          color={
                            item.fireContent.indexOf('排除') > -1 ||
                            item.fireContent === '未见明显异常'
                              ? 'rgba(21, 209, 65, 1)'
                              : 'danger'
                          }
                        >
                          {item.fireContent}d
                        </Tag>
                      )}x
                    </div>
                  </div>
                </div>
              </Card>
            );
          })}
          
        <InfiniteScroll
          loadMore={async () => {
            await loadMore({ pageNo: params.pageNo + 1, flag: true });
          }}
          hasMore={hasMore}
        />
      </div>
    </div>
  );
}
