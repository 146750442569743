// @ts-ignore
/* eslint-disable */
import { request } from 'umi';

export async function list(
  data: any,
  options?: { [key: string]: any },
) {
  const params = data;
  delete params.flag;
  if(localStorage.getItem("topTemplateId")){
    params.topTemplateId = parseInt(localStorage.getItem("topTemplateId"))
  }
  const msg = await request<{
    /** 列表的内容总数 */
    retCode?: number;
    retMsg?: string;
    data: any ;
  }>('/api/report/user/number/list', {
    method: 'POST',
    data: params,
    ...(options || {}),
  });
  if (msg.retCode === 0 && msg.data) {
    return msg.data.data.datas
  } else {
    return []
  }
}